import SvgIcon from '@mui/material/SvgIcon';

const JupLogo = () => {
    return (
        <SvgIcon sx={{ width: 50, height: 50}}>
            <svg width="256" height="256" viewBox="0 0 256 256" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M26.1464 199.614C36.7084 214.305 50.2565 226.598 65.9023 235.686C81.5482 244.774 98.9383 250.452 116.932 252.348C107.675 238.417 94.218 225.6 77.4183 215.842C60.6186 206.084 42.8275 200.754 26.1464 199.614Z" fill="url(#paint0_linear_13278_146915)" />
                <path d="M99.9925 176.988C67.6261 158.185 32.5947 153.393 7.52861 161.858C9.94843 169.856 13.1398 177.599 17.0576 184.979C38.8358 184.475 62.6138 190.39 84.7394 203.241C106.865 216.092 123.787 233.828 134.143 253C142.499 252.743 150.811 251.679 158.963 249.822C153.896 223.858 132.35 195.796 99.9925 176.988Z" fill="url(#paint1_linear_13278_146915)" />
                <path d="M254.229 100.663C250.113 83.925 242.668 68.1877 232.336 54.3909C222.004 40.5942 208.997 29.0209 194.093 20.3621C179.189 11.7034 162.693 6.13675 145.59 3.99461C128.487 1.85247 111.128 3.17875 94.5489 7.89426C122.246 11.2839 152.99 21.6814 183.14 39.1972C213.291 56.7129 237.573 78.2665 254.229 100.663Z" fill="url(#paint2_linear_13278_146915)" />
                <path d="M213.93 162.049C199.753 138.505 175.467 115.959 145.55 98.5793C115.632 81.1992 84.0242 71.2719 56.5727 70.6152C32.4219 70.0432 14.296 77.0639 6.85584 89.8723C6.81347 89.9486 6.75415 90.0206 6.70754 90.0969C6.0381 92.4992 5.46187 94.9058 4.93648 97.3209C15.3256 93.2195 27.3629 90.9358 40.7476 90.6816C70.5124 90.1223 103.824 99.6428 134.563 117.502C165.302 135.361 190.093 159.592 204.346 185.717C210.736 197.488 214.723 209.076 216.303 220.147C218.142 218.503 219.947 216.804 221.697 215.037C221.743 214.957 221.773 214.872 221.82 214.787C229.26 201.966 226.383 182.747 213.93 162.049Z" fill="url(#paint3_linear_13278_146915)" />
                <path d="M122.788 137.754C76.9736 111.137 26.3458 106.968 2 125.543C2.04782 131.357 2.49231 137.161 3.3304 142.915C10.4921 140.744 17.8732 139.377 25.3373 138.839C52.5431 136.792 82.5368 144.372 109.755 160.193C136.974 176.014 158.43 198.326 170.132 222.956C173.367 229.703 175.834 236.792 177.488 244.09C182.902 241.967 188.167 239.48 193.245 236.646C197.321 206.292 168.616 164.375 122.788 137.754Z" fill="url(#paint4_linear_13278_146915)" />
                <path d="M237.496 122.641C223.158 99.1218 198.659 76.5132 168.53 59.0187C138.401 41.5241 106.67 31.4401 79.1295 30.6308C58.1352 30.0249 41.8736 35.1135 33.4419 44.7273C68.4522 38.7955 114.631 48.7651 159.391 74.7676C204.15 100.77 235.699 135.954 247.876 169.303C252.05 157.224 248.419 140.577 237.496 122.641Z" fill="url(#paint5_linear_13278_146915)" />
                <defs>
                    <linearGradient id="paint0_linear_13278_146915" x1="169.969" y1="53.7813" x2="54.0834" y2="253" gradientUnits="userSpaceOnUse">
                        <stop offset="0.0001" stop-color="#A4D756" />
                        <stop offset="1" stop-color="#00B6E7" />
                    </linearGradient>
                    <linearGradient id="paint1_linear_13278_146915" x1="169.969" y1="53.7813" x2="54.0834" y2="253" gradientUnits="userSpaceOnUse">
                        <stop offset="0.0001" stop-color="#A4D756" />
                        <stop offset="1" stop-color="#00B6E7" />
                    </linearGradient>
                    <linearGradient id="paint2_linear_13278_146915" x1="169.969" y1="53.7813" x2="54.0834" y2="253" gradientUnits="userSpaceOnUse">
                        <stop offset="0.0001" stop-color="#A4D756" />
                        <stop offset="1" stop-color="#00B6E7" />
                    </linearGradient>
                    <linearGradient id="paint3_linear_13278_146915" x1="169.969" y1="53.7813" x2="54.0834" y2="253" gradientUnits="userSpaceOnUse">
                        <stop offset="0.0001" stop-color="#A4D756" />
                        <stop offset="1" stop-color="#00B6E7" />
                    </linearGradient>
                    <linearGradient id="paint4_linear_13278_146915" x1="169.969" y1="53.7813" x2="54.0834" y2="253" gradientUnits="userSpaceOnUse">
                        <stop offset="0.0001" stop-color="#A4D756" />
                        <stop offset="1" stop-color="#00B6E7" />
                    </linearGradient>
                    <linearGradient id="paint5_linear_13278_146915" x1="169.969" y1="53.7813" x2="54.0834" y2="253" gradientUnits="userSpaceOnUse">
                        <stop offset="0.0001" stop-color="#A4D756" />
                        <stop offset="1" stop-color="#00B6E7" />
                    </linearGradient>
                </defs>
            </svg>
        </SvgIcon>
    );
};
export default JupLogo;
